<template>
  <div class="wrap">
    <!-- <van-nav-bar title="查看医嘱" left-arrow @click-left="onClickLeft" /> -->
    <div class="content">
      <div class="content_info">
        <div class="title">
          <div class="title_1">医嘱编号</div>
          <div class="title_3">{{ info.orderCode || "-" }}</div>
        </div>
        <!-- <div class="title">
                    <div class="title_1">药品标志</div>
                    <div class="title_3">长期医嘱</div>
                </div> -->
        <div class="title">
          <div class="title_1">开嘱科室</div>
          <div class="title_3">{{ info.orderingDept || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">开嘱医生</div>
          <div class="title_3">{{ info.doctor || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">医嘱正文</div>
          <div class="title_2">{{ info.orderText || "-" }}</div>
        </div>
      </div>
      <div class="content_info">
        <div class="title">
          <div class="title_1">校对护士</div>
          <div class="title_3">{{ info.nurse || "-" }}</div>
        </div>
        <!-- <div class="title">
                    <div class="title_1">执行护士</div>
                    <div class="title_3">{{ info.nurse }}</div>
                </div> -->
        <div class="title">
          <div class="title_1">执行结果</div>
          <div class="title_3">{{ info.performResult || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">执行频率</div>
          <div class="title_3">{{ info.frequency || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">单次剂量</div>
          <div class="title_3">{{ info.dosage || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">给药方式</div>
          <div class="title_3">{{ info.administration || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">持续时间</div>
          <div class="title_3">{{ info.duration || "-" }}</div>
        </div>
      </div>
      <div class="content_info">
        <div class="title">
          <div class="title_1">执行频率描述</div>
          <div class="title_3">{{ info.frequency || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">执行时间描述</div>
          <div class="title_3">{{ info.freqDetail || "-" }}</div>
        </div>
      </div>
      <div class="content_info">
        <div class="title">
          <div class="title_1">最后一次执行时间</div>
          <div class="title_3">{{ info.lastPerformDateTime || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">医嘱停止时间</div>
          <div class="title_3">{{ info.stopDateTime || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">停医嘱医生</div>
          <div class="title_3">{{ info.stopDoctor || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">停医嘱校对护士</div>
          <div class="title_3">{{ info.stopNurse || "-" }}</div>
        </div>
        <div class="title">
          <div class="title_1">停医嘱录入时间</div>
          <div class="title_3">{{ info.stopOrderDateTime || "-" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { doctorsAdvice } from "@/api/doctorAdvice";

export default {
  data() {
    return {
      active: 1,
      value: "",
      info: [],
    };
  },
  created() {
    // this.info = JSON.parse(this.$route.query.info);
    // console.log(this.info);
    window.scrollTo(0, 0);
    this.getData();
  },
  methods: {
    getData() {
      doctorsAdvice({
        orderNo: this.$route.query.orderNo,
        orderSubNo: this.$route.query.orderSubNo,
        patientId: this.$route.query.patientId,
        repeatIndicator: this.$route.query.repeatIndicator,
        tenantId: this.$route.query.tenantId,
        visitId: this.$route.query.visitId,
      }).then((res) => {
        console.log(res,'res55')
        if (res.data.code == "200") {
          this.info = res.data.data.resps[0];
        }
      });
    },
    onClickLeft() {
      this.$router.push({ path: "/doctorAdviceList" });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  .content {
    padding: 16px;
    box-sizing: border-box;

    .content_info {
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 10px 10px 0 10px;
      box-sizing: border-box;
      margin-bottom: 12px;

      .title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 13px;

        .title_1 {
          font-size: 15px;
          color: #333333;
        }

        .title_3 {
          font-size: 14px;
          color: #666666;
        }

        .title_2 {
          font-size: 14px;
          color: #20419c;
          width: 50%;
          text-align: right;
        }
      }

      .detail {
        color: #666666;
        font-size: 14px;
        margin-top: 12px;
      }
    }
  }
}

/deep/.van-cell-group {
  margin: 16px;
  border-radius: 10px;
}

/deep/.van-cell {
  border-radius: 10px;
}
</style>
